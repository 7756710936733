import img1 from '../images/mark/mark-slider-3.png';
import img2 from '../images/mark/mark-header-07.png';
import img3 from '../images/mark/mark-slider-1.jpg';
import img4 from '../images/mark/mark-slider-2.jpg';

import img5 from '../images/mark/mark2-slider-home2.png';
import img6 from '../images/mark/mark1-slider-home2.png';

// import img7 from '../images/mark/mark2-slider-home3.png';
// import img8 from '../images/mark/mark1-slider-home3.png';
// import heroImage from '../images/mnurImages/heroImage.png'
import cone3dShape from '../images/mnurImages/cone3dShape.png'
import hero1 from '../images/mnurImages/hero.png'

const dataBanner = [
    {
        id: 1,
        classAction: 'flat-slider flex',
        title: 'Discover Collec',
        title2: 't',
        title3: 'And Sell',
        title4: 'NFT Token.',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 2,
        title: 'Discover Collec',
        title2: 't',
        title3: 'And Sell',
        title4: 'NFT Token.',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 3,
        title: 'Discover Collec',
        title2: 't',
        title3: 'And Sell',
        title4: 'NFT Token.',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 4,
        title: 'Discover Collec',
        title2: 't',
        title3: 'And Sell',
        title4: 'NFT Token.',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img5,
        img2: img6,
    },
    {
        id: 5,
        title: 'Discover Collec',
        title2: 't',
        title3: 'And Sell',
        title4: 'NFT Token.',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img5,
        img2: img6,
    },
    {
        id: 6,
        title: 'Discover Collec',
        title2: 't',
        title3: 'And Sell',
        title4: 'NFT Token.',
        subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
        img1: img5,
        img2: img6,
    },

    {
        id: 7,
        title: 'Minionaire INU NFTs ',
        title2: 't',
        title3: ' for',
        title4: 'Minionaire Game.',
        subtitle: 'Discover Collect minionaire inu NFTS & use its in Minionaire Inu Game',
        img1: cone3dShape,
        img2: hero1,
    },
    // {
    //     id: 8,
    //     title: 'Discover Collec',
    //     title2: 't',
    //     title3: 'And Sell',
    //     title4: 'NFT Token.',
    //     subtitle: 'Discover Collect minionaire INU NFTS.',
    //     img1: img7,
    //     img2: img8,
    // },
    // {
    //     id: 9,
    //     title: 'Discover Collec',
    //     title2: 't',
    //     title3: 'And Sell',
    //     title4: 'NFT Token.',
    //     subtitle: 'Etiam sit amet magna risus. Pellentesque laoreet, ligula sit amet lacinia.',
    //     img1: img7,
    //     img2: img8,
    // },
]

export default dataBanner;