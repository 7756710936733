import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi';
import '../styles.scss'

function WalletConnectBtn() {
    const { open } = useWeb3Modal()
    const {isConnected , address , status } = useAccount()
  return (
      <div className="sc-btn-top" id="site-header">
        {!isConnected &&(
            <button
        onClick={()=>open()}
        className = {`sc-button header-slider style wallet fl-button pri-1`}
        >
        <span>
            {status === 'disconnected' && "Connect Wallet" }
            {status === 'connecting' && "Connecting..."}
            {status === 'reconnecting' && "Connecting..."}
            {status === 'connected' && status}
        </span>
        </button>
        )}
        {isConnected &&(
            <button
            onClick={() => open({view : 'Account'})}
        className={`sc-button header-slider style wallet pri-1 ${status === 'connected' ? "walletConnected" : ""}
        `}
        >
        <span>Connected</span>
        </button>
        )}
    </div>
  );
}

export default WalletConnectBtn;
