import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { useAccount, useBalance, useReadContract, useSimulateContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import { MNUR_ADDRESS } from '../contracts/addresses'
import mnurAbi from '../contracts/abi/mnurAbi.json'
import nftAbi from '../contracts/abi/nftApi.json'
import { REDUNAIRE_ADDRESS } from '../contracts/addresses'
import { parseEther, parseUnits } from 'viem';
import { writeContract } from 'viem/actions';

const CardModal = (props) => {
    const item = props.item
    const price = item?.price * 1_000_000

    const[balance , setBalance] = useState(0)
    const[allowanceVal , setAllowanceVal] = useState(0)
    const[needApprove , setNeedApprove] = useState(true)

    const { isConnected , address} = useAccount()

    //Balance
    const {data : balanceData} = useBalance({
      address : address,
      token : MNUR_ADDRESS,
      enabled : isConnected && props.show
    })
    useEffect(()=>{
      setBalance(parseInt(balanceData?.formatted))
    } , [balanceData])

    //allowance
    const {data : allowanceSimulateData , refetch : refetchAllowance} = useReadContract({
      abi : mnurAbi,
      address : MNUR_ADDRESS,
      functionName : "allowance",
      args : [`${address}` , `${item?.contractAddress}`],
      enabled : isConnected && props.show
    })
    useEffect(()=>{
      setAllowanceVal(parseInt(allowanceSimulateData)/1e18)
    }, [allowanceSimulateData])
    
    useEffect(()=>{
      allowanceVal >= price ?
        setNeedApprove(false) :
        setNeedApprove(true)
    } , [allowanceVal , price])

    //approve
    const {data : simulateContractApproveData} = useSimulateContract({
      abi : mnurAbi,
      address : MNUR_ADDRESS,
      functionName : "approve",
      args : [`${item?.contractAddress}` , parseUnits(`${price || 1}` , 18) ],
      enabled : needApprove && isConnected && props.show,
    })

    const {
      writeContract : approveRequest,
      isPending : approveIsLoading,
      data: approveTxHash
    } = useWriteContract()

    const {
      isLoading : isConfirming,
      isSuccess : isConfirmed
    } = useWaitForTransactionReceipt({
      hash : approveTxHash
    })
  useEffect(()=>{
    refetchAllowance()
  } , [isConfirmed])

  //mint
  const { data : simulateContractMintData , isError ,error } = useSimulateContract({
    abi : nftAbi,
    address : `${item?.contractAddress}`,
    functionName : "mint",
    args : [`${address}`],
    enabled : isConnected && !needApprove && props.show
  })
  const {
    writeContract : mintRequest,
    isPending : mintLoading,
    data : mintTxHash
  } = useWriteContract()

  const {
    isLoading : isMinting,
    isSuccess : isMinted
  } = useWaitForTransactionReceipt({
    hash : mintTxHash
  })

return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>
        <div className="modal-body space-y-20 pd-40">
          <div className='d-flex justify-content-between'>

            <div className='cardLeft'>
            <h2>Mint {item?.title} </h2>
            <p className="subTitle">Minionaire INU game
            </p>
            <p className='p-title'>Wallet Balance : <p className="color-popup custom-mb-10 posRelative">{balance || 0} <span className='spanMnur'>MNUR</span></p>
            </p>
            <div className="hr"></div>
                                    
            <div className="">
                <p className='p-title'> NFT Price</p>
                <p className="price color-popup posRelative"> {price} <span className='spanMnur'>MNUR</span></p>
            </div>
            </div>
            <div className='cardRight'>
              <img src={item?.img} alt="img" />
            </div>
          </div>
            <button 
            className="btn btn-primary"
            data-toggle="modal" data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
            onClick={()=>{
              if(needApprove){
                  approveRequest(simulateContractApproveData?.request)
              }else{
                mintRequest(simulateContractMintData?.request)
              }
            }}
            disabled = {!isConnected || approveIsLoading || isConfirming || mintLoading || isMinting}
            > 
            {!isConnected && 'Not Connected'}
            {(isConnected && needApprove && !approveIsLoading && !isConfirming) && 'Approve For Mint'}
            {(isConnected && !needApprove && !isMinting && !mintLoading) && 'Mint'}
            {((approveIsLoading || isConfirming || mintLoading) && !isMinting) && "Loading..."}
            { isMinting && "Minting..." }
            </button>
        </div>
    </Modal>
    
  );
};

export default CardModal;
