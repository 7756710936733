import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
Liveauction.propTypes = {
  data: PropTypes.array,
};

function Liveauction(props) {
  const { data } = props;

  const [modalShow, setModalShow] = useState(false);
  const [itemToModal , setItemToModal] = useState()
  const [copied , setCopied] = useState({})

  const copyToClipboard = async item => {
    try {
      await navigator.clipboard.writeText(item?.contractAddress)
      setCopied({...copied , [item?.id] : true})
      setTimeout( ()=>{
        setCopied({...copied , [item?.id] : false})
      } , 3000 )
    } catch (error) {
    }
  }

  // const [countdownDate] = useState(new Date("7/30/2022").getTime());
  // const [state, setState] = useState({
  //   days: 0,
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  // });

  // useEffect(() => {
  //   setInterval(() => setNewTime(), 1000);
  // });

  // useEffect(() => {
  //   setInterval(() => setNewTime(), 1000);
  // });

  // const setNewTime = () => {
  //   if (countdownDate) {
  //     const currentTime = new Date().getTime();

  //     const distanceToDate = countdownDate - currentTime;

  //     let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
  //     let hours = Math.floor(
  //       (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     let minutes = Math.floor(
  //       (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
  //     );
  //     let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

  //     const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  //     days = `${days}`;
  //     if (numbersToAddZeroTo.includes(hours)) {
  //       hours = `0${hours}`;
  //     } else if (numbersToAddZeroTo.includes(minutes)) {
  //       minutes = `0${minutes}`;
  //     } else if (numbersToAddZeroTo.includes(seconds)) {
  //       seconds = `0${seconds}`;
  //     }

  //     setState({ days: days, hours: hours, minutes, seconds });
  //   }
  // };

  const [dataTitle] = useState({
    title: "Explore Minionaire INU NFTS",
    desc: "There are many variations of the Minionaire character, buy or collect and use them in the game to collect more coins and earn MNUR tokens.",
  });
  const { classection } = props;
  return (
    <Fragment>
      <div id="NftsCollection" className={`${classection}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="heading-section2 style">
                <h2 className="fw-5">{dataTitle.title}</h2>
                <p>{dataTitle.desc}</p>
              </div>
            </div>
            <div className="col-lg-12">
              <Swiper
                className="swiper-container carousel-4 auctions show-slider3 btn-collection style2"
                modules={[Navigation, Autoplay]}
                spaceBetween={30}
                navigation
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
              >
                {data.slice(0, 6).map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="sc-card-product sc-card-article blog-article">
                      <div className="card-media flat-img">
                        <div>
                          <img src={item.img} alt="img" />
                        </div>
                      </div>
                      <div className="content">
                        <h6 className="tags">{item.tags}</h6>
                        <h3>
                          {item.title}
                        </h3>
                        <div className="meta-info mb-17">
                          <div className="author">
                            <div className="avatar">
                              <img src={item.imgauthor} alt="" />
                              {/* <img
                                className="check"
                                src={item.icon1}
                                alt="minioniare inu"
                              /> */}
                            </div>
                            <div className="info">
                              <h5>
                                {" "}
                                By <span className="text-color-3">
                                  {` ${item.author}`}
                                </span>{" "}
                              </h5>
                              <div className="date">
                                {" "}
                                <span>{item.date}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="meta-price flex-one">
                          <div className="price flex">
                            <img src={item.icon2} alt="Minionaire" />
                            <div className="title-price">{item.price > 0 ? item.price : ""}M MNUR</div>
                          </div>
                          <div className="button-place-bid">
                            <Link
                              to="#"
                              className="sc-button btn-3"
                              onClick= { () => {
                                if(item.btn === "Mint"){
                                  setItemToModal(item)
                                  setModalShow(true)
                              }  
                              }}
                            >
                              <span>{item.btn}</span>
                            </Link>
                          </div>
                        </div>
                        <div className="featured-countdown">
                          <h4 className="flex-one alignItemCenter">
                            <span className="title-countdown">
                              {(item?.contractAddress).substring(0,2)} ...{(item?.contractAddress).substring(item?.contractAddress.length-6,item?.contractAddress.length)}
                            </span>
                            <button className="copyContractAddress"
                            onClick={e=>{
                              e.preventDefault()
                              copyToClipboard(item)
                            }}
                            >
                             {copied[item?.id] ? "Copied! 🗸" : "Copy Address"}
                            </button>
                            {/* <div className="js-countdown">
                              <div className="countdown__timer">
                                <div className="countdown__item">
                                  {state.days || "0"}
                                </div>
                                <div className="countdown__item countdownhours">
                                  {state.hours || "00"}
                                </div>
                                <div className="countdown__item countdownminutes">
                                  {state.minutes || "00"}
                                </div>
                                <div className="countdown__item countdownseconds">
                                  {state.seconds || "00"}
                                </div>
                              </div>
                            </div> */}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false) } item = {itemToModal} />
    </Fragment>
  );
}

export default Liveauction;
