import img1 from '../images/box-item/top-saller-1.jpg';
import img2 from '../images/box-item/top-saller-2.jpg';
import img3 from '../images/box-item/top-saller-3.jpg';
import img4 from '../images/box-item/top-saller-4.jpg';
import img5 from '../images/box-item/top-saller-5.jpg';
import img6 from '../images/box-item/top-saller-6.jpg';

import icon1 from '../images/icon/icon-check.svg';
import icon2 from '../images/icon/icon-diamond.svg';

import medal from "../images/icon/medal.png"


const dataLiveaution = [
    {
        id: 1,
        img: medal,
        tags: '6 in stock',
        title: '0x ... 218d',
        price: '1560051 MNUR',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 2,
        img: medal,
        tags: '6 in stock',
        title: '0x ... 152k',
        price: '1680127 MNUR',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 3,
        img: medal,
        tags: '6 in stock',
        title: '0x ... 9625',
        price: '506922 MNUR',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 4,
        img: medal,
        tags: '6 in stock',
        title: '0x ... E552',
        price: '2960366 MNUR',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 5,
        img: img5,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 6,
        img: img6,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 7,
        img: img1,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 8,
        img: img2,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 9,
        img: img3,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 10,
        img: img4,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 11,
        img: img5,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 12,
        img: img6,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 13,
        img: img1,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 14,
        img: img2,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 15,
        img: img3,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 16,
        img: img4,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 17,
        img: img5,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 18,
        img: img6,
        tags: '6 in stock',
        title: 'Liam Olivia',
        price: '2.39 ETH',
        btn: 'Follow',
        icon1 : icon1,
        icon2 : icon2,
    },
]

export default dataLiveaution;