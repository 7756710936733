import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { GAME_CLAIM_ADDRESS } from "../contracts/addresses";
import { parseUnits } from "viem";
import gameApi from "../contracts/abi/gameClaimApi.json";
import claimImage from "../assets/images/mnurImages/claim.png";
import axios from "axios";
import { ENV, DEV_BASE_URL, DEV_PLAYER_BASE_URL, PROD_BASE_URL, PROD_PLAYER_BASE_URL, UID, DEV_Claim_BASE_URL, PROD_Claim_BASE_URL } from "../constants";

const RewardsCardModal = (props) => {
  const bas_url = ENV === "development" ? DEV_BASE_URL : PROD_BASE_URL
  const playerBaseUrl= ENV === "development" ? DEV_PLAYER_BASE_URL : PROD_PLAYER_BASE_URL
  const claimUrl = ENV === "development" ? DEV_Claim_BASE_URL : PROD_Claim_BASE_URL

  const { isConnected, address } = useAccount();
  const [playerInfo , setPlayerInfo] = useState([{playerWA : undefined , tokensClaimed : 0 , tokensEarned : 0}])
  const[tokensAvailableToClaim , setTokenAvailableToClaim] = useState(0)

  const[message , setMessage] = useState({ isMessage : false , msg : "" })

  useEffect(()=>{
    !props.show && setMessage({isMessage : false , msg : ""})
  } , [props.show])

  const getDataFromDb = () =>{
    if(isConnected && address){
      try {
        axios.get(`${bas_url}/${playerBaseUrl}/${address}`).then(res => {
          if(res?.data){
            setPlayerInfo(res?.data?.[0])
          }
        })
      } catch (error) {
        
      }
    }
  }

  const postClaimOperation = () => {
    axios
    .post(`${bas_url}/${claimUrl}/` , {
      playerWA : `${address}`,
	    tokenClaimedAmount : tokensAvailableToClaim
    }).then(()=>{
      getDataFromDb()
      setMessage({isMessage : true , msg : "Claimed successfully"})
    })
  }
  useEffect(()=>{
      getDataFromDb()
    } , [isConnected , address , props.show])
    
  useEffect(()=>{
    if(isConnected && playerInfo !== undefined && playerInfo.length !== 0){
      const tokensToClaim = playerInfo?.[0].tokensEarned ?? 0 - playerInfo?.[0].tokensClaimed ?? 0
      tokensToClaim <= 0 ? setTokenAvailableToClaim(0) : setTokenAvailableToClaim(tokensToClaim)
    }
  },[playerInfo , isConnected])

  //isBlackListed
  const { data: isBlackListed , isLoading : blackListedIsLoading } = useReadContract({
    abi: gameApi,
    address: GAME_CLAIM_ADDRESS,
    functionName: "isBlackListed",
    args: [`${address}`],
    enabled: isConnected && props.show,
  });

  //paused
  const { data: paused , isLoading : pausedIsLoading } = useReadContract({
    abi: gameApi,
    address: GAME_CLAIM_ADDRESS,
    functionName: "paused",
    args: [],
    enabled: isConnected && props.show,
  });
  //Claim
  const {
    data: simulateContractClaim,
    isError : sIsError, isLoading : sIsLoading , error
  } = useSimulateContract({
    abi: gameApi,
    address: `${GAME_CLAIM_ADDRESS}`,
    functionName: "ClaimA",
    args: [`${address}`, `${parseUnits(`${tokensAvailableToClaim || 0}` , 18)}`, `${UID}`],
    enabled: isConnected && props.show,
  })
  const {
    writeContract: claimRequest,
    isPending: claimLoading,
    data: claimTxHash,
  } = useWriteContract();

  const { isLoading: isWaitingForTx } =
    useWaitForTransactionReceipt({
      hash: claimTxHash
    });

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <div className="d-flex justify-content-between">
          <div className="cardLeft">
            <h2>Claim Rewards</h2>
            <p className="subTitle">Minionaire INU game</p>
            <div className="hr"></div>

            <div className="">
              <p className="p-title"> Available to Claim</p>
              <p className="price color-popup posRelative">
                {" "}
                {tokensAvailableToClaim || 0} <span className="spanMnur">MNUR</span>
              </p>
            </div>
            {
               message.isMessage &&( <p className="custom-mb-10 posRelative subTitle green"> {message?.msg} </p>)
            }

          </div>
          <div className="cardRight">
            <img src={claimImage} alt="img" />
          </div>
        </div>
        <button
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#popup_bid_success"
          data-dismiss="modal"
          aria-label="Close"
          onClick={ () => { 
           claimRequest?.(
            simulateContractClaim?.request , 
            {
            onSuccess : () => postClaimOperation(),
           })
          }}
          disabled={!isConnected || pausedIsLoading || blackListedIsLoading || sIsLoading || sIsError || paused || isBlackListed || claimLoading}
        >
          {!isConnected && "Not Connected"}
          {isConnected &&  (!pausedIsLoading && !blackListedIsLoading && !sIsLoading &&!isWaitingForTx && !claimLoading)  && "Claim MNUR"}
          {isConnected && (pausedIsLoading || blackListedIsLoading ||isWaitingForTx || sIsLoading || claimLoading ) && "Loading..."}
        </button>
      </div>
    </Modal>
  );
};

export default RewardsCardModal;
