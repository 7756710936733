import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../button/button-st1";
import RewardsCardModal from "../../RewardsCardModal";
Banner.propTypes = {
  item: PropTypes.object,
};

function Banner(props) {
  const { item } = props;
  const [modalShow, setModalShow] = useState(false);

  return (
    <Fragment>
    <div className="themes-container2">
      <div className="flat-wrap">
        <div className="wrap-heading flat-slider flex">
          <img width={300} className="absolute mark-page8" src={item.img1} alt="" />
          <div className="content">
            <h1 className="heading">
              {item.title}
              {/* <span className="s2">{item.title2}</span> */}
              {item.title3}{" "}
              <span className="tf-text style s1">{item.title4}</span>{" "}
            </h1>
            <p className="sub-heading">{item.subtitle}</p>
            <div className="flat-bt-slider flex style2">
              <Button
                title="Explore NFTS"
                addclass="sc-button style-1 fl-button"
                path="#NftsCollection"
                />
              <Button
                title="Play Now"
                addclass="sc-button style-1 btn-5"
                onClick = {() => {
                  window.location.href = "https://game.minionaireinu.com/"
                }}
                />
              <Button
                title="Claim Rewards"
                addclass="sc-button style-2 fl-button"
                path=""
                onClick = { () => setModalShow(true) }
                />
            </div>
          </div>
          <div className="img-slider">
            <div className="box-img">
              <div className="image ">
                <img className="animate-up" src={item.img2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RewardsCardModal show={modalShow} onHide={() => setModalShow(false) } item = {null} />
    </Fragment>
  );
}

export default Banner;
