import { React } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from './pages';
import '../src/assets/fonts/font-awesome.css'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { bsc, bscTestnet } from 'wagmi/chains'
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { metadata, projectId } from './constants';

const cl = () => {
  if(process.env.NODE_ENV === "production"){
    console.log(" MinioniareInu.com ✌️ ")
    console.log = function(){}
    console.debug = function(){}
  }
}
cl()

const queryClient = new QueryClient()
const chains = [bsc, bscTestnet]
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata
})
createWeb3Modal({ wagmiConfig: config, projectId, chains })

function App() {
  return (
    <div className="body header-fixed">
      <>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient} >
            <Routes>
              {
                routes.map((data, idx) => (
                  <Route key={idx} path={data.path} element={data.component} exact />
                ))
              }
            </Routes>
          </QueryClientProvider>
        </WagmiProvider>
      </>
    </div>
  );
}

export default App;
