// import img1 from '../images/box-item/auctions-1.jpg';
// import img2 from '../images/box-item/auctions-4.jpg';
// import img3 from '../images/box-item/auctions-3.jpg';
// import img4 from '../images/box-item/auctions-2.jpg';

// import img5 from '../images/box-item/auctions-5.jpg';
// import img6 from '../images/box-item/auctions-6.jpg';
import img7 from '../images/box-item/auctions-3.jpg';
import img8 from '../images/box-item/auctions-7.jpg';
import img9 from '../images/box-item/auctions-8.jpg';
import img10 from '../images/box-item/auctions-9.jpg';
import img11 from '../images/box-item/auctions-10.jpg';
import img12 from '../images/box-item/auctions-11.jpg';

import redunaireW from "../images/minionaireV/N-redunaire.png"
import greenunaire from "../images/minionaireV/N-greenunaire.png"
import bluenunaire from "../images/minionaireV/N-blununaire.png"
import orangunaire from "../images/minionaireV/orangunaire.png"
import violunaire from "../images/minionaireV/violunaire.png"
import pinkunaire from "../images/minionaireV/pinkunaire.png"


import imgauthor1 from '../images/logo/mnurLogo100.png'

import icon1 from '../images/icon/author-check.svg';
import { REDUNAIRE_ADDRESS } from '../../contracts/addresses'
import { BLUNUNAIRE_ADDRESS } from '../../contracts/addresses'
import { ORANGUNAIRE_ADDRESS } from '../../contracts/addresses'
import { GREENUNAIRE_ADDRESS } from '../../contracts/addresses'

const dataLiveaution = [
    {
        id: 1,
        img: redunaireW,
        tags: 'Mint live',
        title: 'Redunaire',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        price: 50,
        btn: 'Mint',
        icon1 : icon1,
        icon2 : imgauthor1,
        titlecountdown: REDUNAIRE_ADDRESS || "",
        contractAddress : REDUNAIRE_ADDRESS,
        copyBtn : "Copy Address"
    },
    {
        id: 2,
        img: greenunaire,
        tags: 'Mint live',
        title: 'Greenunaire',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        price: 50,
        btn: 'Mint',
        titlecountdown: GREENUNAIRE_ADDRESS || "",
        icon1 : icon1,
        icon2 : imgauthor1,
        contractAddress : GREENUNAIRE_ADDRESS,
        copyBtn : "Copy Address"
    },
    {
        id: 3,
        img: bluenunaire,
        tags: 'Mint live',
        title: 'Blunaire',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        price: 50,
        btn: 'Mint',
        titlecountdown: BLUNUNAIRE_ADDRESS || "",
        icon1 : icon1,
        icon2 : imgauthor1,
        contractAddress : BLUNUNAIRE_ADDRESS,
        copyBtn : "Copy Address"
    },
    {
        id: 4,
        img: orangunaire,
        tags: 'Mint live',
        title: 'Orangunaire',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        price: 50,
        btn: 'Mint',
        titlecountdown: ORANGUNAIRE_ADDRESS || "",
        icon1 : icon1,
        icon2 : imgauthor1,
        contractAddress :ORANGUNAIRE_ADDRESS,
        copyBtn : "Copy Address"
    },

    {
        id: 5,
        img: violunaire,
        tags: 'Upcoming',
        title: 'Violunaire',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        price: 0,
        btn: 'Upcoming',
        icon1 : icon1,
        icon2 : imgauthor1,
        titlecountdown: "",
        contractAddress : "",
        copyBtn : "Copy Address"
    },
    {
        id: 6,
        img: pinkunaire,
        tags: 'Upcoming',
        title: 'Pinkunaire',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        price: 0,
        btn: 'Upcoming',
        titlecountdown:  "",
        icon1 : icon1,
        icon2 : imgauthor1,
        contractAddress :"",
        copyBtn : "Copy Address"
    },
    {
        id: 7,
        img: img7,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: 50,
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },
    {
        id: 8,
        img: img8,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: 50,
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },
    {
        id: 9,
        img: img9,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },

    {
        id: 10,
        img: img10,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        icon1 : icon1,
        icon2 : imgauthor1,
        titlecountdown: 'Explore and buy on Opensea',
    },
    {
        id: 11,
        img: img9,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },
    {
        id: 12,
        img: img10,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },
    {
        id: 13,
        img: img11,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        icon1 : icon1,
        icon2 : imgauthor1,
        titlecountdown: 'Explore and buy on Opensea',
    },
    {
        id: 14,
        img: img12,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },
    {
        id: 15,
        img: img7,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },
    {
        id: 16,
        img: img8,
        tags: '100 available',
        title: 'Axtronic Electronics VS-10',
        imgauthor: imgauthor1,
        author: 'Minionaire INU',
        date: '@ 3d Models',
        titleprice: '0.2 BNB',
        btn: 'Mint',
        titlecountdown: 'Explore and buy on Opensea',
        icon1 : icon1,
        icon2 : imgauthor1,
    },

]

export default dataLiveaution;