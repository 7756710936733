const menus = [
    {
        id: 1,
        name: 'Home',
        links: '#wrapper'
    },
    {
        id: 2,
        name: 'Explore',
        links: '#NftsCollection',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Explore',
        //         links: '/explore'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Live Auctions',
        //         links: '/liveAuction'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Live Auctions Details',
        //         links: '/liveAuctionDetails'
        //     },
        // ]
    },
    {
        id: 3,
        name: 'Weapons',
        links: '#weapons',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'About',
        //         links: '/about'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Author',
        //         links: '/author'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Author Profile',
        //         links: '/profile'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Edit Profile',
        //         links: '/editProfile'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Connect Wallet',
        //         links: '/connectwallet'
        //     },
        //     {
        //         id: 6,
        //         sub: 'Create Item',
        //         links: '/createItem'
        //     },
        //     {
        //         id: 7,
        //         sub: 'Login',
        //         links: '/login'
        //     },
        //     {
        //         id: 8,
        //         sub: 'Register',
        //         links: '/register'
        //     },
        //     {
        //         id: 9,
        //         sub: 'Faq',
        //         links: '/Faq'
        //     },
        //     {
        //         id: 10,
        //         sub: 'Popular Collections',
        //         links: '/popular-collections'
        //     }
            
        // ]
    },
    {
        id: 4,
        name: 'Winners',
        links: '#winners',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Blog',
        //         links: '/blog'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Blog Details',
        //         links: '/blog-details'
        //     },
        // ],
    },
    {
        id: 5,
        name: 'Contact',
        links: '#contact',
    },
    
]

export default menus;