import img1 from '../images/icon/survie.png';
import img2 from '../images/icon/coin.png';
import img3 from '../images/icon/earn.png';

const data = [
    {
        id: 1,
        img: img1,
        title : 'Survive',
        desc : 'Survive from collisions, and preserve the limited number lives.',
    },
    {
        id: 2,
        img: img2,
        title : 'Fight',
        desc : 'Use weapons and destroy the enemies, kill as many as possible .',
    },
    {
        id: 3,
        img: img3,
        title : 'Earn',
        desc : 'Exchange all your coins, and earn MNUR tokens.',
    },
]

export default data;