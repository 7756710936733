const DEV_BASE_URL = "http://localhost:5000"
const DEV_PLAYER_BASE_URL = "player"
const DEV_GAME_STATE_BASE_URL = "gameState"
const DEV_GAME_HISTORY_BASE_URL = "gameHistory"
const DEV_Claim_BASE_URL = "claim"


const PROD_BASE_URL = "https://s.7853509.s.7853509.minionaireinu.com"
const PROD_PLAYER_BASE_URL = "player"
const PROD_GAME_STATE_BASE_URL = "gameState"
const PROD_GAME_HISTORY_BASE_URL = "gameHistory"
const PROD_Claim_BASE_URL = "claim"
const UID = 78535097853509
const ENV = process.env.NODE_ENV

console.log(process.env.REACT_APP_API_KEY)

const projectId = "e4a34b9a30ee7d7011bab0bc29476b54"
const metadata = {
    name: "Minionaire NFTs",
    description: "An innovative and dynamic meme cryptocurrency project",
    url: "https://nft.minionaireinu.com",
    icons: ["https://nfts.minionaireinu.com/static/media/mnurLogo100.8677aaae7e3f8b20361c.png"]
  }


export {
    DEV_BASE_URL,
    DEV_PLAYER_BASE_URL,
    DEV_GAME_HISTORY_BASE_URL,
    DEV_GAME_STATE_BASE_URL,
    DEV_Claim_BASE_URL,
    PROD_BASE_URL,
    PROD_GAME_HISTORY_BASE_URL,
    PROD_GAME_STATE_BASE_URL,
    PROD_PLAYER_BASE_URL,
    PROD_Claim_BASE_URL,
    UID,
    ENV,
    projectId,
    metadata
}