import img1 from '../images/box-item/cetagories-1.png';
import img2 from '../images/box-item/cetagories-2.png';
import img3 from '../images/box-item/cetagories-3.png';
import img4 from '../images/box-item/cetagories-4.png';
import img5 from '../images/box-item/cetagories-5.png';
import img6 from '../images/box-item/cetagories-6.png';
import img7 from '../images/box-item/cetagories-7.png';
import img8 from '../images/box-item/cetagories-8.png';
import img9 from '../images/box-item/cetagories-9.png';

import img10 from '../images/box-item/cetagories-10.jpg';
import img11 from '../images/box-item/cetagories-11.jpg';
import img12 from '../images/box-item/cetagories-12.jpg';
import img13 from '../images/box-item/cetagories-13.jpg';
import img14 from '../images/box-item/cetagories-14.jpg';
import img15 from '../images/box-item/cetagories-15.jpg';

import weaponsBg from '../images/mnurImages/weaponsBG.png'

const dataBanner = [
    {
        id: 1,
        title: 'Creative Arts',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 2,
        title: 'Virtual World',
        desc: '20+ Items',
        img: weaponsBg,
    },
    {
        id: 3,
        title: '3D Art',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 4,
        title: 'Books',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 5,
        title: 'Music',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 6,
        title: 'Animation',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 7,
        title: 'Discovery',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 8,
        title: '3D Modeling',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 9,
        title: 'Galaxy',
        desc: '50+ Items',
        img: weaponsBg,
    },

    {
        id: 10,
        title: 'Gun',
        desc: 'weapons',
        img: weaponsBg,
    },
    {
        id: 11,
        title: 'Double guns',
        desc: 'weapons',
        img: weaponsBg,
    },
    {
        id: 12,
        title: 'Triple guns',
        desc: 'weapons',
        img: weaponsBg,
    },
    {
        id: 13,
        title: 'Four guns',
        desc: 'weapons',
        img: weaponsBg,
    },
    {
        id: 14,
        title: 'Books Arts',
        desc: '50+ Items',
        img: weaponsBg,
    },
    {
        id: 15,
        title: 'Music Arts',
        desc: '50+ Items',
        img: weaponsBg,
    },
]

export default dataBanner;